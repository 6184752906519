var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.url)?_c('router-link',{class:['button',{'button--color-primary' : _vm.color === 'primary'},
																										{'button--color-secondary' : _vm.color === 'secondary'},
																										{'button--color-tertiary' : _vm.color === 'tertiary'},
																										{'button--color-success' : _vm.color === 'success'},
																										{'button--color-info' : _vm.color === 'info'},
																										{'button--color-warning' : _vm.color === 'warning'},
																										{'button--color-danger' : _vm.color === 'danger'},
																										{'button--color-dark' : _vm.color === 'dark'},
																										{'button--color-light' : _vm.color === 'light'},
																										{'button--type-outline' : _vm.type === 'outline'},
																										{'button--type-pill' : _vm.type === 'pill'},
																										{'button--type-soft' : _vm.type === 'soft'},
																										{'button--size-small' : _vm.size === 'small'},
																										{'button--size-large' : _vm.size === 'large'},
																										{'button--width-full' : _vm.full},
																										{'button--overlap' : _vm.overlap}],attrs:{"to":_vm.url,"target":_vm.newTab ? '_blank': ''}},[_c('p',{staticClass:"button__label"},[_vm._v(_vm._s(_vm.label))])]):(!_vm.url)?_c('button',{class:['button',{'button--color-primary' : _vm.color === 'primary'},
																						{'button--color-secondary' : _vm.color === 'secondary'},
																						{'button--color-tertiary' : _vm.color === 'tertiary'},
																						{'button--color-success' : _vm.color === 'success'},
																						{'button--color-info' : _vm.color === 'info'},
																						{'button--color-warning' : _vm.color === 'warning'},
																						{'button--color-danger' : _vm.color === 'danger'},
																						{'button--color-dark' : _vm.color === 'dark'},
																						{'button--color-light' : _vm.color === 'light'},
																						{'button--type-outline' : _vm.type === 'outline'},
																						{'button--type-pill' : _vm.type === 'pill'},
																						{'button--type-soft' : _vm.type === 'soft'},
																						{'button--size-small' : _vm.size === 'small'},
																						{'button--size-large' : _vm.size === 'large'},
																						{'button--width-full' : _vm.full},
																						{'button--overlap' : _vm.overlap}]},[_c('p',{staticClass:"button__label"},[_vm._v(_vm._s(_vm.label))])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }