<template>
	<router-link v-if="url" :to="url" :target="newTab ? '_blank': ''" :class="['button',{'button--color-primary' : color === 'primary'},
																											{'button--color-secondary' : color === 'secondary'},
																											{'button--color-tertiary' : color === 'tertiary'},
																											{'button--color-success' : color === 'success'},
																											{'button--color-info' : color === 'info'},
																											{'button--color-warning' : color === 'warning'},
																											{'button--color-danger' : color === 'danger'},
																											{'button--color-dark' : color === 'dark'},
																											{'button--color-light' : color === 'light'},
																											{'button--type-outline' : type === 'outline'},
																											{'button--type-pill' : type === 'pill'},
																											{'button--type-soft' : type === 'soft'},
																											{'button--size-small' : size === 'small'},
																											{'button--size-large' : size === 'large'},
																											{'button--width-full' : full},
																											{'button--overlap' : overlap}]">
		<p class="button__label">{{label}}</p>
	</router-link>
	<button v-else-if="!url" :class="['button',{'button--color-primary' : color === 'primary'},
																							{'button--color-secondary' : color === 'secondary'},
																							{'button--color-tertiary' : color === 'tertiary'},
																							{'button--color-success' : color === 'success'},
																							{'button--color-info' : color === 'info'},
																							{'button--color-warning' : color === 'warning'},
																							{'button--color-danger' : color === 'danger'},
																							{'button--color-dark' : color === 'dark'},
																							{'button--color-light' : color === 'light'},
																							{'button--type-outline' : type === 'outline'},
																							{'button--type-pill' : type === 'pill'},
																							{'button--type-soft' : type === 'soft'},
																							{'button--size-small' : size === 'small'},
																							{'button--size-large' : size === 'large'},
																							{'button--width-full' : full},
																							{'button--overlap' : overlap}]">
		<p class="button__label">{{label}}</p>
	</button>
</template>

<script>
export default {
	props: {
		label: String,
		url: String,
		color: String,
		type: String,
		size: String,
		full: Boolean,
		overlap: Boolean,
		newTab: Boolean,
	},
  mounted(){
		window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>

.button{
	background: var(--button-default-background);
	border: var(--button-default-border);
	border-radius: var(--button-default-border-radius);
	padding: var(--button-default-padding); 
	transition: var(--button-default-transition);
	text-decoration: none; 
	display: inline-flex;
	justify-content: center;
	align-items: center;
	z-index: 1;

	.button__label{
		margin: 0;
		line-height: var(--button-default-line-height);
		font-family:var(--button-default-font-family);
		font-size: var(--button-default-font-size);
		font-weight: var(--button-default-font-weight);
		color: var(--button-default-color);
		transition: var(--button-default-transition);
		text-transform: var(--button-default-text-transform);
		letter-spacing: var(--button-default-letter-spacing);
		white-space: nowrap;
	}
	&:hover{
		background: var(--button-default-hover-background);
		border: var(--button-default-hover-border);
		.button__label{
			color: var(--button-default-hover-color);
		}
	}

	&.button--type-outline{
		border: var(--button-default-outline-border);
		background: var(--button-default-outline-background);
		.button__label{
			color: var(--button-default-outline-color);
		}
		
		&:hover{
			background: var(--button-default-outline-hover-background);
			border: var(--button-default-outline-hover-border);
			.button__label{
				color: var(--button-default-outline-hover-color);
			}
		}
	}
	&.button--type-soft{
		border: var(--button-default-soft-border);
		background: var(--button-default-soft-background);
		.button__label{
			color: var(--button-default-soft-color);
		}
		&:hover{
			background: var(--button-default-soft-hover-background);
			border: var(--button-default-soft-hover-border);
			.button__label{
				color: var(--button-default-soft-hover-color);
			}
		}
	}
	&.button--color-primary{	
		border: var(--button-primary-border);
		background: var(--button-primary-background);
		.button__label{
			color: var(--button-primary-color);
		}
		
		&:hover{
			background: var(--button-primary-hover-background);
			border: var(--button-primary-hover-border);
			.button__label{
				color: var(--button-primary-hover-color);
			}
		}

		&.button--type-outline{
			border: var(--button-primary-outline-border);
			background: var(--button-primary-outline-background);
			.button__label{
				color: var(--button-primary-outline-color);
			}
		
			&:hover{
				background: var(--button-primary-outline-hover-background);
				border: var(--button-primary-outline-hover-border);
				.button__label{
					color: var(--button-primary-outline-hover-color);
				}
			}
		}
		&.button--type-soft{
			border: var(--button-primary-soft-border);
			background: var(--button-primary-soft-background);
			.button__label{
				color: var(--button-primary-soft-color);
			}
		
			&:hover{
				background: var(--button-primary-soft-hover-background);
				border: var(--button-primary-soft-hover-border);
				.button__label{
					color: var(--button-primary-soft-hover-color);
				}
			}
		}
	}
	&.button--color-secondary{
		border: var(--button-secondary-border);
		background: var(--button-secondary-background);
		.button__label{
			color: var(--button-secondary-color);
		}
		
		&:hover{
			background: var(--button-secondary-hover-background);
			border: var(--button-secondary-hover-border);
			.button__label{
				color: var(--button-secondary-hover-color);
			}
		}

		&.button--type-outline{
			border: var(--button-secondary-outline-border);
			background: var(--button-secondary-outline-background);
			.button__label{
				color: var(--button-secondary-outline-color);
			}
		
			&:hover{
				background: var(--button-secondary-outline-hover-background);
				border: var(--button-secondary-outline-hover-border);
				.button__label{
					color: var(--button-secondary-outline-hover-color);
				}
			}
		}
		&.button--type-soft{
			border: var(--button-secondary-soft-border);
			background: var(--button-secondary-soft-background);
			.button__label{
				color: var(--button-secondary-soft-color);
			}
			&:hover{
				background: var(--button-secondary-soft-hover-background);
				border: var(--button-secondary-soft-hover-border);
				.button__label{
					color: var(--button-secondary-soft-hover-color);
				}
			}
		}
	}
	&.button--color-tertiary{
		border: var(--button-tertiary-border);
		background: var(--button-tertiary-background);
		.button__label{
			color: var(--button-tertiary-color);
		}
		&:hover{
			background: var(--button-tertiary-hover-background);
			border: var(--button-tertiary-hover-border);
			.button__label{
				color: var(--button-tertiary-hover-color);
			}
		}

		&.button--type-outline{
			border: var(--button-tertiary-outline-border);
			background: var(--button-tertiary-outline-background);
			.button__label{
				color: var(--button-tertiary-outline-color);
			}
			&:hover{
				background: var(--button-tertiary-outline-hover-background);
				border: var(--button-tertiary-outline-hover-border);
				.button__label{
					color: var(--button-tertiary-outline-hover-color);
				}
			}
		}
		&.button--type-soft{
			border: var(--button-tertiary-soft-border);
			background: var(--button-tertiary-soft-background);
			.button__label{
				color: var(--button-tertiary-soft-color);
			}
			&:hover{
				background: var(--button-tertiary-soft-hover-background);
				border: var(--button-tertiary-soft-hover-border);
				.button__label{
					color: var(--button-tertiary-soft-hover-color);
				}
			}
		}
	}
	&.button--color-success{
		border: var(--button-success-border);
		background: var(--button-success-background);
		.button__label{
			color: var(--button-success-color);
		}
		&:hover{
			background: var(--button-success-hover-background);
			border: var(--button-success-hover-border);
			.button__label{
				color: var(--button-success-hover-color);
			}
		}

		&.button--type-outline{
			border: var(--button-success-outline-border);
			background: var(--button-success-outline-background);
			.button__label{
				color: var(--button-success-outline-color);
			}
			&:hover{
				background: var(--button-success-outline-hover-background);
				border: var(--button-success-outline-hover-border);
				.button__label{
					color: var(--button-success-outline-hover-color);
				}
			}
		}
		&.button--type-soft{
			border: var(--button-success-soft-border);
			background: var(--button-success-soft-background);
			.button__label{
				color: var(--button-success-soft-color);
			}
			&:hover{
				background: var(--button-success-soft-hover-background);
				border: var(--button-success-soft-hover-border);
				.button__label{
					color: var(--button-success-soft-hover-color);
				}
			}
		}
	}
	&.button--color-info{
		border: var(--button-info-border);
		background: var(--button-info-background);
		.button__label{
			color: var(--button-info-color);
		}
		&:hover{
			background: var(--button-info-hover-background);
			border: var(--button-info-hover-border);
			.button__label{
				color: var(--button-info-hover-color);
			}
		}

		&.button--type-outline{
			border: var(--button-info-outline-border);
			background: var(--button-info-outline-background);
			.button__label{
				color: var(--button-info-outline-color);
			}
			&:hover{
				background: var(--button-info-outline-hover-background);
				border: var(--button-info-outline-hover-border);
				.button__label{
					color: var(--button-info-outline-hover-color);
				}
			}
		}
		&.button--type-soft{
			border: var(--button-info-soft-border);
			background: var(--button-info-soft-background);
			.button__label{
				color: var(--button-info-soft-color);
			}
			&:hover{
				background: var(--button-info-soft-hover-background);
				border: var(--button-info-soft-hover-border);
				.button__label{
					color: var(--button-info-soft-hover-color);
				}
			}
		}
	}
	&.button--color-warning{
		border: var(--button-warning-border);
		background: var(--button-warning-background);
		.button__label{
			color: var(--button-warning-color);
		}
		&:hover{
			background: var(--button-warning-hover-background);
			border: var(--button-warning-hover-border);
			.button__label{
				color: var(--button-warning-hover-color);
			}
		}

		&.button--type-outline{
			border: var(--button-warning-outline-border);
			background: var(--button-warning-outline-background);
			.button__label{
				color: var(--button-warning-outline-color);
			}
			&:hover{
				background: var(--button-warning-outline-hover-background);
				border: var(--button-warning-outline-hover-border);
				.button__label{
					color: var(--button-warning-outline-hover-color);
				}
			}
		}
		&.button--type-soft{
			border: var(--button-warning-soft-border);
			background: var(--button-warning-soft-background);
			.button__label{
				color: var(--button-warning-soft-color);
			}
			&:hover{
				background: var(--button-warning-soft-hover-background);
				border: var(--button-warning-soft-hover-border);
				.button__label{
					color: var(--button-warning-soft-hover-color);
				}
			}
		}
	}
	&.button--color-danger{
		border: var(--button-danger-border);
		background: var(--button-danger-background);
		.button__label{
			color: var(--button-danger-color);
		}
		&:hover{
			background: var(--button-danger-hover-background);
			border: var(--button-danger-hover-border);
			.button__label{
				color: var(--button-danger-hover-color);
			}
		}

		&.button--type-outline{
			border: var(--button-danger-outline-border);
			background: var(--button-danger-outline-background);
			.button__label{
				color: var(--button-danger-outline-color);
			}
			&:hover{
				background: var(--button-danger-outline-hover-background);
				border: var(--button-danger-outline-hover-border);
				.button__label{
					color: var(--button-danger-outline-hover-color);
				}
			}
		}
		&.button--type-soft{
			border: var(--button-danger-soft-border);
			background: var(--button-danger-soft-background);
			.button__label{
				color: var(--button-danger-soft-color);
			}
			&:hover{
				background: var(--button-danger-soft-hover-background);
				border: var(--button-danger-soft-hover-border);
				.button__label{
					color: var(--button-danger-soft-hover-color);
				}
			}
		}
	}
	&.button--color-dark{
		border: var(--button-dark-border);
		background: var(--button-dark-background);
		.button__label{
			color: var(--button-dark-color);
		}
		&:hover{
			background: var(--button-dark-hover-background);
			border: var(--button-dark-hover-border);
			.button__label{
				color: var(--button-dark-hover-color);
			}
		}

		&.button--type-outline{
			border: var(--button-dark-outline-border);
			background: var(--button-dark-outline-background);
			.button__label{
				color: var(--button-dark-outline-color);
			}
			&:hover{
				background: var(--button-dark-outline-hover-background);
				border: var(--button-dark-outline-hover-border);
				.button__label{
					color: var(--button-dark-outline-hover-color);
				}
			}
		}
		&.button--type-soft{
			border: var(--button-dark-soft-border);
			background: var(--button-dark-soft-background);
			.button__label{
				color: var(--button-dark-soft-color);
			}
			&:hover{
				background: var(--button-dark-soft-hover-background);
				border: var(--button-dark-soft-hover-border);
				.button__label{
					color: var(--button-dark-soft-hover-color);
				}
			}
		}
	}
	&.button--color-light{
		border: var(--button-light-border);
		background: var(--button-light-background);
		.button__label{
			color: var(--button-light-color);
		}
		&:hover{
			background: var(--button-light-hover-background);
			border: var(--button-light-hover-border);
			.button__label{
				color: var(--button-light-hover-color);
			}
		}

		&.button--type-outline{
			border: var(--button-light-outline-border);
			background: var(--button-light-outline-background);
			.button__label{
				color: var(--button-light-outline-color);
			}
			&:hover{
				background: var(--button-light-outline-hover-background);
				border: var(--button-light-outline-hover-border);
				.button__label{
					color: var(--button-light-outline-hover-color);
				}
			}
		}
		&.button--type-soft{
			border: var(--button-light-soft-border);
			background: var(--button-light-soft-background);
			.button__label{
				color: var(--button-light-soft-color);
			}
			&:hover{
				background: var(--button-light-soft-hover-background);
				border: var(--button-light-soft-hover-border);
				.button__label{
					color: var(--button-light-soft-hover-color);
				}
			}
		}
	}

	&.button--type-pill{
		border-radius: var(--button-default-pill-border-radius);
	}
	&.button--size-small{
		border-radius: var(--button-small-border-radius);
		padding: var(--button-small-padding); 

		.button__label{
			line-height: var(--button-small-line-height);
			font-size: var(--button-small-font-size);
			font-weight: var(--button-small-font-weight);
			font-family:var(--button-small-font-family);
		}
	}
	&.button--size-large{
		border-radius: var(--button-large-border-radius);
		padding: var(--button-large-padding); 

		.button__label{
			line-height: var(--button-large-line-height);
			font-size: var(--button-large-font-size);
			font-weight: var(--button-large-font-weight);
			font-family:var(--button-large-font-family);
		}
	}
	&.button--width-full{
		width: 100%;
	}
	&.button--overlap{
		margin-top: -30px;
	}

}
	
</style>